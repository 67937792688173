.overlay{
  position: fixed;
  z-index: 99999;
  background: rgba(18, 20, 22, 0.95);
  width: 100%;
  height: 100%;
  padding: 140px 0 90px;
  display: none;

  @include media-breakpoint-down(sm){
    padding: 70px 0;
  }

  &-close{
    position: absolute;
    right: 0;
    top: -100px;

    @include media-breakpoint-down(sm){
      top: -65px;
    }

    &:hover, &:focus{
      text-decoration: none;
    }

    i{
      font-size: 48px;
      color: $ashen-100;
    }
  }
}