html {
  height: 100%;
  width: 100%;

  body {
    height: 100%;
    width: 100%;
    font-family: $primaryFont;
    font-weight: 400;
    font-size: 15px;
    color: $ashen-400;
    letter-spacing: 0.2px;
    overflow-x: hidden;

    p {
      line-height: 25px;
      letter-spacing: 0.35px;
    }

    a {
      transition: all $animationTime ease-in-out;
      color: $pink-400;

      &:hover {
        cursor: pointer;
        opacity: 0.75;
        color: $pink-400;
      }

      &:focus {
        color: $pink-400;
      }

      &:active {
        color: $pink-400;
      }
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    strong {
      font-weight: 600;
    }

    .sectionTitle{
      font-size: 23px;
      color: $grey-600;
      font-weight: 600;
      letter-spacing: -0.7px;
      margin: 0 0 40px;
    }

    .sectionLargeTitle{
      text-align: center;
      position: relative;
      width: 100%;

      > h1{
        font-size: 32px;
        color: $grey-900;
        font-weight: 600;
      }

      &-link{
        position: absolute;
        right: 0;
        top: 31px;

        @include media-breakpoint-down(sm){
          position: static;
          justify-content: center;
          display: flex;
          margin-left: 12px;
        }
      }
    }

    .footerTitle{
      font-size: 15px;
      color: $white;
      font-weight: 600;
      margin-bottom: 26px;
    }

  }
}