.fullSlider {
  width: 100%;
  height: 100%;
  background-size: cover;
  position: relative;

  @include media-breakpoint-down(sm) {
    height: auto;
  }

  header {
    .fullSlider-header {
      padding: 40px 0;

      @include media-breakpoint-down(sm) {
        padding: 20px 0;
      }

      @include media-breakpoint-down(xs) {
        padding: 8px 0;
      }

      &-logo {
        @include media-breakpoint-down(lg) {
          display: flex;
          justify-content: center;
        }

        @include media-breakpoint-down(xs) {
          display: flex;
          justify-content: center;
        }

        a {
          &:hover {
            opacity: 0.75;
          }
        }
      }

      &-mainNav {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 4px;

        @include media-breakpoint-down(lg) {
          justify-content: center;
        }

        @include media-breakpoint-down(sm) {
          margin: 24px 0;
          padding: 0;
        }

        > ul {
          display: flex;
          justify-content: flex-end;

          > li {
            display: flex;
            align-items: center;

            @include media-breakpoint-down(sm) {
              display: none;
            }

            &:first-child {
              > a {
                margin: 0 20px 0 0;

                @include media-breakpoint-down(sm) {
                  margin: 0;
                }
              }
            }

            &:last-child {
              > a {
                margin: 0 0 0 20px;

                @include media-breakpoint-down(sm) {
                  margin: 0;
                }
              }
            }

            > a {
              color: $white;
              margin: 0 20px;
              letter-spacing: 0.7px;

              @include media-breakpoint-down(sm) {
                margin: 0;
              }

              &:hover, &:focus, &:active {
                text-decoration: none;
              }
            }
          }

          .fullSlider-header-mainNav-icon {
            position: relative;

            @include media-breakpoint-down(sm) {
              display: flex;
            }

            &:last-child {
              > a {
                margin: 4px 0 0 12px;
              }
            }

            > a {
              margin: 4px 12px 0;

              > i {
                font-size: 30px;

                @include media-breakpoint-down(sm) {
                  font-size: 42px;
                }
              }
            }

            #cartItems {
              position: absolute;
              top: 4px;
              right: -5px;
              background-color: $pink-400;
              border-radius: 15px;
              height: 15px;
              width: 15px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 0;
              padding: 0;
              font-size: 9px;
            }
          }

          .dropdown {
            &:first-child {
              > .dropdown-toggle {
                margin: 0 10px 0 0;
              }
            }

            &:last-child {
              > .dropdown-toggle {
                margin: 0 0 0 10px;
              }
            }

            > .dropdown-toggle {
              margin: 0 10px;
            }
          }
        }
      }

      &-mobileMenuBtn {
        display: none;

        @include media-breakpoint-down(sm) {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }

        @include media-breakpoint-down(xs) {
          justify-content: center;
          margin-top: 15px;
        }

        .menu-btn {
          color: $white;
          background: $pink-400;
          padding: 10px 28px;
          transition: all $animationTime ease-in-out;

          &:hover {
            cursor: pointer;
            opacity: 0.7;
          }
        }
      }

    }
  }

  &-text {
    position: absolute;
    top: 23%;
    left: 0;
    right: 0;

    @include media-breakpoint-down(sm) {
      position: static;
    }

    &-content {
      margin: 0;

      > h1 {
        font-size: 46px;
        letter-spacing: -2px;
        color: $white;
        text-align: center;

        @include media-breakpoint-down(sm) {
          font-size: 32px;
        }

        > strong {
          color: $pink-400;
        }
      }

      > h2 {
        text-align: center;
        font-size: 17px;
        color: $white;
        margin-top: 24px;
        letter-spacing: 0.8px;

        @include media-breakpoint-down(sm) {
          font-size: 15px;
          margin-top: 14px;
          line-height: 20px;
          margin-bottom: 30px;
        }
      }
    }
  }

  &-content {
    position: absolute;
    top: 42%;
    left: 0;
    right: 0;

    @include media-breakpoint-down(sm) {
      position: static;
      padding-bottom: 15px;
    }

    &-news {
      &-img {
        position: relative;

        img {
          width: 100%;
          height: auto;
        }

        .hot {
          position: absolute;
          top: 16px;
          right: 16px;
          background: $pink-400;
          color: $white;
          text-transform: uppercase;
          padding: 5px 14px;
          font-size: 12px;
          margin: 0;
        }
      }

      &-content {
        background: $white;
        padding: 20px 30px;

        &-info {

          p {
            color: $ashen-300;
            margin-right: 30px;
            margin-bottom: 0;
            font-size: 13px;
            display: inline-block;
            position: relative;

            &:after {
              position: absolute;
              right: -19px;
              top: 8px;
              content: '';
              height: 4px;
              width: 4px;
              background: $ashen-400;
              border-radius: 8px;
              display: inline-block;
              margin-left: 15px;
            }

            > a {
              color: $ashen-300;
            }
          }

          .date {
            &:after {
              height: 0;
              width: 0;
            }
          }

          .comments {
            display: flex;
            margin-right: 0;
            float: right;

            &:after {
              display: none;
            }

            > i {
              font-size: 24px;
              margin-right: 8px;
              margin-top: -2px;
            }
          }
        }

        > .title {
          line-height: 28px;
          margin-top: 8px;
          margin-bottom: 4px;

          > a {
            font-size: 24px;
            color: $grey-600;

            &:hover {
              color: $pink-400;
            }
          }
        }
      }
    }
  }
}