.blog {
  margin-top: 70px;
  box-shadow: 0 4px 75px -35px rgba(0,0,0,0.85);
  position: relative;
  z-index: 999;

  @include media-breakpoint-down(sm){
    padding: 30px 0;
    margin: 0;
  }

  &-articles {
    width: 100%;
    margin-bottom: 60px;

    &-img {
      box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
      position: relative;

      .hot {
        position: absolute;
        top: 16px;
        right: 16px;
        background: $pink-400;
        color: $white;
        text-transform: uppercase;
        padding: 5px 14px;
        font-size: 12px;
        margin: 0;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    &-info {
      margin: 22px 0 12px;

      p {
        color: $ashen-300;
        margin-right: 30px;
        margin-bottom: 0;
        font-size: 13px;
        display: inline-block;
        position: relative;

        &:after {
          position: absolute;
          right: -19px;
          top: 11px;
          content: '';
          height: 4px;
          width: 4px;
          background: $ashen-400;
          border-radius: 8px;
          display: inline-block;
          margin-left: 15px;
        }

        > a {
          color: $ashen-300;
        }
      }

      .date {
        &:after {
          height: 0;
          width: 0;
        }
      }

      .comments {
        display: flex;
        margin-right: 0;
        float: right;

        &:after {
          display: none;
        }

        > i {
          font-size: 24px;
          margin-right: 8px;
          margin-top: -2px;
        }
      }
    }

    > .title {
      line-height: 28px;
      margin-top: 8px;
      margin-bottom: 4px;

      > a {
        font-size: 24px;
        color: $grey-600;

        &:hover {
          color: $pink-400;
        }
      }
    }

    > p {
      color: $ashen-300;
      margin: 26px 0;
    }

    &-more{
      text-align: center;
      display: flex;
      justify-content: center;
    }

  }

  &-sidebar {
    padding-left: 45px;

    @include media-breakpoint-down(sm){
      padding-left: 15px;
    }

    &-categories {
      margin-bottom: 50px;

      nav {
        ul {
          li {
            border-bottom: 1px dashed $ashen-50;
            display: block;
            padding: 14px 7px;

            &:last-child {
              border-bottom: 0;
            }

            a {
              color: $ashen-400;
              padding: 0;
              margin: 0;

              &:hover {
                color: $pink-400;
              }
            }

            ul {
              margin-left: 21px;
              li {
                border-bottom: 0;
                padding: 9px 7px;

                &:first-child {
                  margin-top: 7px;
                }

                &:last-child {
                  padding: 9px 7px 5px;
                }
              }
            }
          }
        }
      }
    }

    &-adv {
      margin-bottom: 50px;
      a {
        &:hover {
          text-decoration: none;
        }

        img {
          width: 100%;
          height: auto;
        }

        p {
          font-size: 11px;
          color: $grey-200;
          text-align: center;
          margin: 7px 0;
        }
      }
    }

    &-popularPosts {
      margin-bottom: 50px;
      &-post {
        display: flex;
        margin-bottom: 30px;

        @include media-breakpoint-down(md){
          flex-wrap: wrap;
        }

        > a{
          @include media-breakpoint-down(md){
            width: 100%;
          }

          img{
            @include media-breakpoint-down(md){
              width: 100%;
              height: auto;
            }
          }
        }

        &-text {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          margin-left: 15px;

          @include media-breakpoint-down(md){
            margin-left: 0;
            margin-top: 15px;
          }

          h2 {
            margin: 0 0 4px;
            line-height: 21px;
            font-size: 15px;

            @include media-breakpoint-down(sm){
              width: 100%;
              font-size: 22px;
            }

            a {
              color: $grey-900;
            }
          }

          p {
            font-size: 12px;
            color: $ashen-300;
            margin: 0;

            @include media-breakpoint-down(sm){
              font-size: 15px;
              margin-top: 8px;
            }
          }
        }
      }
    }

    &-share {
      ul {
        li {
          float: left;
          width: 25%;

          @include media-breakpoint-down(lg){
            width: 50%;
            margin-bottom: 15px;
          }

          @include media-breakpoint-down(sm){
            width: 25%;
            margin-bottom: 0;
          }

          @include media-breakpoint-down(xs){
            width: 50%;
            margin-bottom: 15px;
          }

          a {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            &:hover {
              text-decoration: none;

              i {
                opacity: 0.75;
              }
            }

            i {
              color: $white;
              width: 35px;
              height: 35px;
              font-size: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              transition: opacity $animationTime ease-in-out;
              margin-bottom: 12px;
            }

            .amount {
              font-size: 15px;
              color: $grey-900;

            }

            p {
              font-size: 12px;
              color: $ashen-300;
              margin: -5px 0 0;
              width: 100%;
              text-align: center;
            }
          }
        }

        .blog-sidebar-share-facebook {
          a{
            i{
              background: $facebook;
            }
          }
        }

        .blog-sidebar-share-twitter {
          a{
            i{
              background: $twitter;
            }
          }
        }

        .blog-sidebar-share-google {
          a{
            i{
              background: $google;
            }
          }
        }

        .blog-sidebar-share-github {
          a{
            i{
              background: $github;
            }
          }
        }
      }

    }
  }
}