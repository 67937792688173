/*! Pushy - v1.0.0 - 2016-3-1
* Pushy is a responsive off-canvas navigation menu using CSS transforms & transitions.
* https://github.com/christophery/pushy/
* by Christopher Yee */
/* Menu Appearance */
.pushy {
  position: fixed;
  width: 200px;
  height: 100%;
  top: 0;
  z-index: 9999;
  background: #191918;
  overflow: auto;
  visibility: hidden;
  -webkit-overflow-scrolling: touch;
  /* enables momentum scrolling in iOS overflow elements */
}
.pushy a {
  display: block;
  color: #b3b3b1;
  padding: 15px 30px;
  text-decoration: none;
  outline: 0;
}
.pushy a:hover {
  color: #FFF;
}
.pushy ul:first-child {
  margin-top: 10px;
}
.pushy.pushy-left {
  left: 0;
}
.pushy.pushy-right {
  right: 0;
}

/* Menu Movement */
.pushy-left {
  -webkit-transform: translate3d(-200px, 0, 0);
  -ms-transform: translate3d(-200px, 0, 0);
  transform: translate3d(-200px, 0, 0);
}

.pushy-open-left #container,
.pushy-open-left .push {
  -webkit-transform: translate3d(200px, 0, 0);
  -ms-transform: translate3d(200px, 0, 0);
  transform: translate3d(200px, 0, 0);
}

.pushy-right {
  -webkit-transform: translate3d(200px, 0, 0);
  -ms-transform: translate3d(200px, 0, 0);
  transform: translate3d(200px, 0, 0);
}

.pushy-open-right #container,
.pushy-open-right .push {
  -webkit-transform: translate3d(-200px, 0, 0);
  -ms-transform: translate3d(-200px, 0, 0);
  transform: translate3d(-200px, 0, 0);
}

.pushy-open-left .pushy,
.pushy-open-right .pushy {
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/* Menu Transitions */
#container,
.pushy,
.push {
  transition: transform 0.2s cubic-bezier(0.16, 0.68, 0.43, 0.99);
}

/* Site Overlay */
.site-overlay {
  display: none;
}

.pushy-open-left .site-overlay,
.pushy-open-right .site-overlay {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9998;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-animation: fade 500ms;
  animation: fade 500ms;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* Submenu Appearance */
.pushy-submenu {
  /* Submenu Icon */
}
.pushy-submenu ul {
  padding-left: 15px;
  transition: max-height 0.2s ease-in-out;
}
.pushy-submenu ul .pushy-link {
  transition: opacity 0.2s ease-in-out;
}
.pushy-submenu > a {
  position: relative;
}
.pushy-submenu > a::after {
  content: '';
  display: block;
  height: 11px;
  width: 8px;
  position: absolute;
  top: 50%;
  right: 15px;
  background: url("fonts/arrow.svg") no-repeat;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: transform 0.2s;
}

/* Submenu Movement */
.pushy-submenu-closed ul {
  max-height: 0;
  overflow: hidden;
}
.pushy-submenu-closed .pushy-link {
  opacity: 0;
}

.pushy-submenu-open {
  /* Submenu Icon */
}
.pushy-submenu-open ul {
  max-height: 1000px;
}
.pushy-submenu-open .pushy-link {
  opacity: 1;
}
.pushy-submenu-open a::after {
  -webkit-transform: translateY(-50%) rotate(90deg);
  -ms-transform: translateY(-50%) rotate(90deg);
  transform: translateY(-50%) rotate(90deg);
}

.no-csstransforms3d .pushy-submenu-closed ul {
  max-height: none;
  display: none;
}

/*# sourceMappingURL=pushy.css.map */
