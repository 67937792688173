button{
  &:focus{
    outline: 0;
  }
}

.btn{
  background: $ashen-400;
  color: $white;
  border-radius: 0;
  font-size: 15px;
  padding: 12px 36px;
  transition: $animationTime ease-in-out;

  &:hover{
    color: $white;
    opacity: 0.7;
    cursor: pointer;
  }

  &:active{
    color: $white;
  }

  &:focus{
    color: $white;
  }
}

.btn-pink{
  background: $pink-400;
  color: $white;
}

.btn-green{
  background: $green-400;
  color: $white;
}

.linkUnderline{
  color: $ashen-300;
  display: flex;

  &:hover{
    text-decoration: none;
  }

  > span{
    text-decoration: underline;
  }

  > i{
    font-size: 24px;
    margin-top: 1px;
  }
}

.link{
  color: $ashen-300;
  display: flex;

  &:hover{
    text-decoration: none;

    > span{
      text-decoration: underline;
    }
  }

  > i{
    font-size: 24px;
    margin-top: 1px;
  }
}