.contact{
  padding: 60px 0;
  background: #f7f7f7;

  &-form{
    padding: 40px 0 0;
    text-align: center;

    form{
      > button{
        width: 100%;
      }
    }

    &-footerText{
      font-size: 21px;
      font-weight: 600;
      color: $ashen-300;
      margin-top: 30px;
    }
  }
}