.features{
  margin: 70px 0;

  &-one{
    @include media-breakpoint-down(sm){
      margin-bottom: 30px;
    }

    > i {
      font-size: 36px;
      color: $grey-900;
      display: block;
    }

    > i[class="pe-7s-phone"]{
      margin-left: -6px;
    }

    > h2 {
      font-size: 18px;
      color: $grey-900;
      font-weight: 600;
      padding: 30px 0;
      margin: 0;
    }

    &-line{
        height: 2px;
        width: 40px;
        background: $pink-400;
        display: block;
    }

    > p {
      font-size: 12px;
      line-height: 20px;
      color: $ashen-300;
      margin: 30px 0 0;
    }
  }
}