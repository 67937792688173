.themes {
  padding: 60px 0;
  background: $ashen-30;
  background-image: url('../img/bg-section.png');
  background-repeat: repeat repeat;

  .sectionLargeTitle {
    > h1 {
      color: $pink-400;
    }
  }

  &-wrapper {
    &-theme {
      margin-top: 50px;
      position: relative;
      &:hover {
        .modules-module-content {
          box-shadow: 0 0 36px rgba(0, 0, 0, 0.15);
        }

        > a {
          > img {
            opacity: 0.75;
          }
        }
      }

      > a {
        > img {
          transition: all $animationTime ease-in-out;
          width: 95%;
          height: auto;
          margin: 0 auto;
          display: block;
        }
      }

      &-content {
        padding: 70px 30px 0;
        box-shadow: 0 0 18px rgba(0, 0, 0, 0.06);
        transition: all $animationTime ease-in-out;
        margin-top: -50px;
        background: #eeeef4; /* Old browsers */
        background: -moz-linear-gradient(top,  #eeeef4 0%, #ffffff 40%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  #eeeef4 0%,#ffffff 40%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  #eeeef4 0%,#ffffff 40%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eeeef4', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */

        &-title {
          display: flex;
          margin-bottom: 14px;

          &-wrapper{
            margin-left: 16px;

            > h1 {
              font-size: 16px;
              font-weight: 600;
              margin: 0;
              line-height: 28px;
              > a {
                color: $grey-900;
              }
            }

            > p {
              font-size: 12px;
              margin: 0;
              line-height: 16px;
            }
          }


        }

        > p {
          font-size: 12px;
          line-height: 20px;
          color: $ashen-300;
        }

        &-price {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 -30px;
          border-top: 1px solid $grey-50;

          > p {
            width: 50%;
            font-size: 17px;
            color: $pink-400;
            font-weight: 600;
            margin: 0 0 0 30px;
          }
          > a {
            width: 50%;
            font-size: 14px;
            padding: 12px 26px;

            @include media-breakpoint-down(md){
              padding: 12px 16px;
            }
          }
        }

        &-version{
          margin-bottom: 15px;

          p{
            margin: 0;
            font-size: 13px;
            line-height: 20px;
          }
        }
      }
    }

    .slick-arrow{
      font-size: 90px;
      position: absolute;
      z-index: 999;
      top: 260px;
      display: flex;
      align-items: center;

      @include media-breakpoint-down(xs){
        top: inherit;
        bottom: -75px;
      }

      &:hover{
        i{
          color: $pink-400;
        }
      }

      i {
        color: $grey-200;
        transition: all $animationTime ease-in-out;
      }
    }

    .slick-next{
      right: -100px;

      @include media-breakpoint-down(lg){
        right: -60px;
      }

      @include media-breakpoint-down(sm){
        right: -50px;
      }

      @include media-breakpoint-down(xs){
        right: 10%;
      }
    }

    .slick-prev{
      left: -100px;

      @include media-breakpoint-down(lg){
        left: -60px;
      }

      @include media-breakpoint-down(sm){
        left: -50px;
      }

      @include media-breakpoint-down(xs){
        left: 10%;
      }
    }
  }
}