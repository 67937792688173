.fullSlider-fullTheme {
  &-carousel {
    &-theme {
      text-align: center;
      padding-bottom: 270px;
      padding-top: 30px;
      position: relative;

      @include media-breakpoint-down(sm){
        padding-bottom: 140px;
        padding-top: 20px;
      }

      @include media-breakpoint-down(xs){
        padding-bottom: 90px;
        padding-top: 20px;
      }

      > h1 {
        color: $white;
        font-size: 64px;
        font-weight: 600;

        @include media-breakpoint-down(sm){
          font-size: 48px;
        }

        @include media-breakpoint-down(xs){
          font-size: 40px;
        }
      }

      > p {
        color: $ashen-300;
        margin-top: 10px;
        margin-bottom: 0;

        @include media-breakpoint-down(xs){
          padding: 0 30px;
        }
      }

      > a {
        margin-top: 20px;
      }

      > img {
        position: absolute;
        bottom: -206px;
        left: 0;
        right: 0;
        margin: 0 auto;


        @include media-breakpoint-down(md){
          height: 79%;
          width: auto;
          bottom: -150px;
        }

        @include media-breakpoint-down(sm){
          height: 81%;
          bottom: -150px;
        }

        @include media-breakpoint-down(xs){
          height: 58%;
          bottom: -94px;
        }
      }
    }
  }

  &-footer {
    position: relative;
    text-align: center;
    bottom: -180px;
    border-bottom: 1px solid $grey-50;
    padding-bottom: 50px;

    @include media-breakpoint-down(sm){
      bottom: -160px;
      padding-bottom: 20px;
    }

    @include media-breakpoint-down(xs){
      bottom: -105px;
    }

    > .linkUnderline {
      justify-content: center;
      margin-left: 16px;
    }
  }

  .slick-list {
    overflow: visible;
  }

  .slick-arrow {
    font-size: 120px;
    position: absolute;
    z-index: 999;
    top: 320px;
    display: flex;
    align-items: center;

    @include media-breakpoint-down(sm){
      top: 250px;
    }

    @include media-breakpoint-down(xs){
      top: 106px;
    }

    i {
      color: $white;
    }
  }

  .slick-next {
    right: 15%;

    @media (min-width: 1800px) {
      right: 22%;
    }

    @media (min-width: 1200px) and (max-width: 1400px) {
      right: 8%;
    }

    @include media-breakpoint-down(lg){
      right: 3%;
    }

    @include media-breakpoint-down(md){
      right: -2%;
    }

    @include media-breakpoint-down(xs){
      right: -8%;
    }
  }

  .slick-prev {
    left: 15%;

    @media (min-width: 1800px) {
      left: 22%;
    }

    @media (min-width: 1200px) and (max-width: 1400px) {
      left: 8%;
    }

    @include media-breakpoint-down(lg){
      left: 3%;
    }

    @include media-breakpoint-down(md){
      left: -2%;
    }

    @include media-breakpoint-down(xs){
      left: -8%;
    }
  }
}