.newsletter {
  background: $pink-400;
  height: 90px;
  display: flex;
  align-items: center;

  @include media-breakpoint-down(lg){
    height: auto;
  }

  &-text {
    > p {
      color: $white;
      font-size: 21px;
      display: flex;
      align-items: center;
      margin-bottom: 0;
      margin-top: 5px;

      @include media-breakpoint-down(lg){
        justify-content: center;
        margin: 15px 0;
      }

      > strong {
        font-weight: 700;
        margin: 0 8px;
      }

      > i {
        font-size: 44px;
        margin-right: 10px;
        margin-top: -4px;
      }
    }
  }

  &-textDecor{
    display: flex;
    align-items: center;
    justify-content: center;

    p{
      color: $white;
      margin: 8px 0 0 -65px;

      @include media-breakpoint-down(lg){
        margin: 0 0 15px;
      }
    }
  }

  &-form{
    .form-control{
      border: none;
    }

    @include media-breakpoint-down(lg){
      margin-bottom: 15px;
    }
  }

}