.portfolio {
  padding: 60px 0;
  background: $ashen-30;
  background-image: url('../img/bg-section.png');
  background-repeat: repeat repeat;

  &-wrapper {
    &-one {
      margin-top: 50px;
      &:hover {
        > a {
          > img {
            box-shadow: 0 0 36px rgba(0, 0, 0, 0.15);
          }
        }

        .modules-module-content {
          box-shadow: 0 0 36px rgba(0, 0, 0, 0.15);
        }
      }

      > a {
        > img {
          box-shadow: 0 0 18px rgba(0, 0, 0, 0.06);
          transition: all $animationTime ease-in-out;
          width: 100%;
          height: auto;
        }
      }

      &-content {
        padding: 20px 20px 0;
        border-bottom: 1px solid $grey-50;
        border-right: 1px solid $grey-50;
        border-left: 1px solid $grey-50;
        box-shadow: 0 0 18px rgba(0, 0, 0, 0.06);
        transition: all $animationTime ease-in-out;
        background: $white;

        > h1 {
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          margin-bottom: 14px;

          > a {
            color: $grey-900;
          }
        }

        > p {
          font-size: 12px;
          color: $ashen-300;
          line-height: 20px;
        }

        &-more {
          justify-content: center;
          border-top: 1px solid $grey-50;
          margin: 0 -15px 0 -3px;
          padding: 12px 0;
          text-align: center;
        }
      }
    }

    .slick-arrow{
      font-size: 90px;
      position: absolute;
      z-index: 999;
      top: 210px;
      display: flex;
      align-items: center;

      @include media-breakpoint-down(xs){
        top: inherit;
        bottom: -75px;
      }

      &:hover{
        i{
          color: $green-400;
        }
      }

      i {
        color: $grey-200;
        transition: all $animationTime ease-in-out;
      }
    }

    .slick-next{
      right: -100px;

      @include media-breakpoint-down(lg){
        right: -60px;
      }

      @include media-breakpoint-down(sm){
        right: -50px;
      }

      @include media-breakpoint-down(xs){
        right: 10%;
      }
    }

    .slick-prev{
      left: -100px;

      @include media-breakpoint-down(lg){
        left: -60px;
      }

      @include media-breakpoint-down(sm){
        left: -50px;
      }

      @include media-breakpoint-down(xs){
        left: 10%;
      }
    }
  }
}