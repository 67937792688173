.fullSlider-landing{
  height: auto;
  margin-bottom: 179px;

  @include media-breakpoint-down(sm){
    margin-bottom: 140px;
  }

  @include media-breakpoint-down(xs){
    margin-bottom: 80px;
  }
}