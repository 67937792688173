footer{
  background: $ashen-800;
  background-image: url('../img/bg-section-footer.png');
  background-repeat: repeat repeat;
  .subFooter{

    padding: 40px 0;


    &-logoFollow{
      @include media-breakpoint-down(sm){
        margin-bottom: 30px;
      }

      > a {
        display: block;
        margin-bottom: 20px;
        margin-left: -8px;
        margin-top: -6px;
      }

      > ul {
        display: flex;
        li{
          a{
            margin-right: 10px;
            font-size: 20px;
            color: $white;
            width: 35px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover{
              text-decoration: none;
            }
          }
        }
      }
    }

    &-text{
      @include media-breakpoint-down(sm){
        margin-bottom: 30px;
      }

      p{
        font-size: 12px;
        color: $grey-200;
        line-height: 18px;
      }
    }

    &-menu{

      ul{
        li{
          a{
            font-size: 13px;
            color:  $ashen-50;
            margin: 8px 0;
            display: block;
          }
        }
      }
    }
  }

  .footer{
    background: rgba(26, 29, 31, 0.7);
    height: 80px;
    display: flex;
    align-items: center;

    p{
      margin: 0;
      color: $grey-200;
      font-size: 14px;
    }
  }
}