$ashen-400: #5a6270;
$ashen-800: #292c2f;
$ashen-300: #858c97;
$ashen-100: #d1d1da;
$ashen-50: #e5e5e7;
$ashen-30: #edeef3;
$pink-400: #df0067;
$green-400: #57b196;
$grey-600: #0f1113;
$grey-900: #121416;
$grey-200: #9b9b9b;
$grey-50: #f0f0f6;
$white: #fff;
$facebook: #4867aa;
$twitter: #1da1f2;
$google: #DD4E41;
$github: #201f1f;
