.slicknav_menu{
  display: none;
  background: $ashen-800;
  background-image: url('../img/bg-section.png');
  background-repeat: repeat repeat;

  @include media-breakpoint-down(sm){
    display: block;
  }

  .slicknav_btn{
    background: $pink-400;
    color: $white;

    .slicknav_menutxt{
      text-shadow: none;
    }
  }

  .fullSlider-header-mainNav-icon{
    display: none;
  }

  .slicknav_nav{
    ul{
      &:first-child{
        margin: 0;
      }
    }
  }
}