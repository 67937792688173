.modules {
  padding: 60px 0;
  box-shadow: 0 0px 30px 0 rgba(0,0,0,0.12);
  position: relative;
  z-index: 999;

  .sectionLargeTitle {
    > h1 {
      color: $green-400;
    }

    > .linkUnderline{
      &:hover{
        color: $green-400;
      }
    }
  }

  &-wrapper {
    &-module {
      margin-top: 50px;
      &:hover {
        > a {
          > img {
            box-shadow: 0 0 36px rgba(0, 0, 0, 0.15);
          }
        }

        .modules-module-content {
          box-shadow: 0 0 36px rgba(0, 0, 0, 0.15);
        }
      }

      > a {
        > img {
          box-shadow: 0 0 18px rgba(0, 0, 0, 0.06);
          transition: all $animationTime ease-in-out;
          width: 100%;
          height: auto;
        }
      }

      &-content {
        padding: 20px 20px 0;
        border-bottom: 1px solid $grey-50;
        border-right: 1px solid $grey-50;
        border-left: 1px solid $grey-50;
        box-shadow: 0 0 18px rgba(0, 0, 0, 0.06);
        transition: all $animationTime ease-in-out;
        background: $white;

        //@include media-breakpoint-up(sm){
        //  min-height: 315px;
        //}

        &-title {
          display: flex;
          margin-bottom: 14px;

          > h1 {
            font-size: 16px;
            font-weight: 600;
            margin-top: 3px;
            line-height: 22px;

            @include media-breakpoint-up(sm){
              min-height: 44px;
            }

            > a {
              color: $grey-900;

              &:hover{
                color: $green-400;
              }
            }
          }
        }

        > p {
          font-size: 12px;
          line-height: 20px;
          color: $ashen-300;
          min-height: 160px;
        }

        &-price {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 -20px;
          border-top: 1px solid $grey-50;

          > p {
            width: 50%;
            font-size: 17px;
            color: $green-400;
            font-weight: 600;
            margin: 0 0 0 16px;
          }
          > a {
            width: 50%;
            font-size: 14px;
            padding: 12px 26px;

            @include media-breakpoint-down(md){
              padding: 12px 16px;
            }
          }
        }
      }
    }

    .slick-arrow{
      font-size: 90px;
      position: absolute;
      z-index: 999;
      top: 260px;
      display: flex;
      align-items: center;

      @include media-breakpoint-down(xs){
        top: inherit;
        bottom: -75px;
      }

      &:hover{
        i{
          color: $green-400;
        }
      }

      i {
        color: $grey-200;
        transition: all $animationTime ease-in-out;
      }
    }

    .slick-next{
      right: -100px;

      @include media-breakpoint-down(lg){
        right: -60px;
      }

      @include media-breakpoint-down(sm){
        right: -50px;
      }

      @include media-breakpoint-down(xs){
        right: 10%;
      }
    }

    .slick-prev{
      left: -100px;

      @include media-breakpoint-down(lg){
        left: -60px;
      }

      @include media-breakpoint-down(sm){
        left: -50px;
      }

      @include media-breakpoint-down(xs){
        left: 10%;
      }
    }
  }
}