.dropdown{
  > .dropdown-toggle {
    &:after{
      content: "\e688";
      font-family: 'Pe-icon-7-stroke';
      border: none;
      font-size: 18px;
      display: inline;
      margin-left: 6px;
      margin-top: 2px;
    }
  }

  .dropdown-menu{
    border-radius: 0;
    border: none;
    padding: 0;


    .dropdown-item{
      width: auto;
      display: flex;
      border-bottom: 1px solid $ashen-50;
      padding: 0;

      &:hover{
        background: none;
        color: $ashen-400;
      }

      &:last-child{
        border-bottom: none;
      }

      a{
        color: $ashen-400;
        width: 100%;
        padding: 14px 15px;
        font-size: 13px;
        margin: 0;

        &:hover{
          text-decoration: none;
          background: $ashen-50;
          color: $ashen-400;
        }
      }
    }
  }
}

input{
  &:focus{
    outline: 0;
  }
}

.form-control{
  border-radius: 0;
  font-size: 15px;
  padding: 14px 20px;
  color: $ashen-300;
  border: 1px solid $ashen-100;

  &:focus{
    border-color: $pink-400;
  }
}

.input-group{
  &-addon.btn{
    background: $grey-900;
  }

  .form-control{
    &:focus{
      border-color: $grey-900;
    }
  }
}

.form-control-line{
  background: none;
  color: $ashen-100;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid $white;
  font-size: 15px;
  padding: 14px 0;
  width: 100%;
  transition: all $animationTime ease-in-out;

  &:focus{
    border-bottom: 1px solid $pink-400;
  }

  &::placeholder {
    color: $ashen-300;
  }
}

.form-control-line-btnIcon{
  background: none;
  font-size: 15px;
  color: $pink-400;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid $pink-400;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  transition: all $animationTime ease-in-out;

  &:hover{
    opacity: 0.7;
    cursor: pointer;
  }

  > i {
    font-size: 28px;
    margin-right: 10px;
  }

  > span{

  }
}
