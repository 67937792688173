.pushy-open-right{
  .site-overlay{
    @include media-breakpoint-up(sm){
      background: none;
    }
  }
}

.pushy{
  width: 300px;
}

.pushy-left{
  transform: translate3d(-300px, 0, 0);
}

.pushy-open-left{
  transform: translate3d(0, 0, 0);
}

.pushy-close{
  display: none;
  background: $pink-400;
  color: $white;
  transition: all $animationTime ease-in-out;

  &:hover{
    cursor: pointer;
    opacity: 0.7;
  }

  @include media-breakpoint-down(sm){
    display: inline-block;
    margin: 30px;
    padding: 0 20px 10px 12px;

  }

  > i {
    font-size: 30px;
    position: relative;
    top: 8px;
  }
}